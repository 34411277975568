// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/Users/will/Develop/mti/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-contact-js": () => import("/Users/will/Develop/mti/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-templates-post-js": () => import("/Users/will/Develop/mti/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/Users/will/Develop/mti/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/will/Develop/mti/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

